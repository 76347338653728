import { Document, PHOTO_MODE } from 'utils/types/IDocument';
import stageAddressImage from './stages/address.jpg';
import stageCFDImage from './stages/cfd.jpg';
import stageCorporateImage from './stages/corporate.jpg';
import stageEnhancedImage from './stages/enhanced.jpg';
import stageIdentityImage from './stages/identity.jpg';
import driverLicenseBack from './documents/driver-license/back.jpg';
import driverLicenseFront from './documents/driver-license/front.jpg';
import selfie from './documents/selfie/selfie.jpg';
import nationalIdBack from './documents/national-id/back.jpg';
import nationalIdFront from './documents/national-id/front.jpg';
import passportFront from './documents/passport/front.jpg';
import resolution from './documents/signed-resolution/resolution.jpg';
import logo from './logo.svg';
import mobileAppQR from './mobile-app-qr.png';

enum COUNTRY {
  GB = 'GB',
}

export const images = {
  stages: {
    identity: stageIdentityImage,
    address: stageAddressImage,
    enhanced: stageEnhancedImage,
    corporate: stageCorporateImage,
    cfd: stageCFDImage,
  },
  logo,
  mobileAppQR,
  documents: {
    corporate: {
      signedResolution: resolution
    },
    [COUNTRY.GB]: {
      [Document.DRIVER_LICENCE]: {
        [PHOTO_MODE.BACK]: driverLicenseBack,
        [PHOTO_MODE.FRONT]: driverLicenseFront,
        [PHOTO_MODE.SELFIE]: selfie,
        [PHOTO_MODE.SELFIE_WITH_NOTE]: selfie,
      },
      [Document.NATIONAL_ID]: {
        [PHOTO_MODE.BACK]: nationalIdBack,
        [PHOTO_MODE.FRONT]: nationalIdFront,
        [PHOTO_MODE.SELFIE]: selfie,
        [PHOTO_MODE.SELFIE_WITH_NOTE]: selfie,
      },
      [Document.PASSPORT]: {
        [PHOTO_MODE.FRONT]: passportFront,
        [PHOTO_MODE.SELFIE]: selfie,
        [PHOTO_MODE.SELFIE_WITH_NOTE]: selfie,
      },
    },
  }
};

